@import "~@ui/styles/tools";
@import "../../styles/variables";

.content {
    display: flex;
    gap: 24px;
    height: 432px;

    @include media-tablet {
        height: 393px;
        gap: 16px;
    }

    @include media-tablet-portrait {
        flex-direction: column;
        height: auto;
    }
}

.card {
    position: relative;
    flex: 1;
    border-radius: 12px;
    overflow: hidden;
    height: 100%;

    &__block {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 32px;
        height: 100%;
        z-index: 1;
    }

    &__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 8px;

        @include media-tablet-portrait {
            flex-direction: row;
            align-items: center;
        }

        @include media-mobile {
            flex-direction: column;
            align-items: unset;
            gap: 24px;
        }
    }

    &__image {
        object-fit: cover;
    }

    &__title {
        font-size: 40px;
        line-height: 1.2;
        font-weight: 500;
        color: $color-off-white;

        @include media-table-large {
            font-size: 32px;
        }
    }

    &__price-container {
        background: #1E6353;
        padding: 24px;
        border-radius: 12px;
        width: fit-content;
        height: fit-content;
    }

    &__price {
        font-size: 52px;
        line-height: 1.2;
        font-weight: 500;
        color: $color-off-white;
        white-space: nowrap;

        @include media-table-large {
            font-size: 48px;
        }

        @include media-tablet {
            font-size: 32px;
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &__description {
        font-size: 16px;
        line-height: 1.4;
        font-weight: 400;
        color: $color-off-white;

        &_desktop {
            @include media-tablet-portrait {
                display: none;
            }
        }

        &_mobile {
            @include media-tablet-portrait-up {
                display: none;
            }
        }
    }
}

.reports {
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 1;
    overflow: auto;
    padding-right: 6px;

    @include media-tablet-portrait {
        max-height: 348px;
        flex: unset;
    }

    &__row {
        overflow: unset;
        flex-direction: row;
        gap: 24px;
        padding: 0;

        @include media-desktop-md {
            gap: 12px;
        }

        @include media-tablet-portrait {
            flex-direction: column;
        }
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        width: 100%;
        background: $color-page-background;
        padding: 20px 24px;
        border-radius: 12px;
        height: 136px;

        @include media-tablet {
            padding: 16px 20px;
            height: 123px;
        }

        @include media-tablet-portrait {
            height: 108px;
        }

        @include media-mobile {
            gap: 16px;
        }
    }
}
