@import "~@ui/styles/variables";
@import "~@ui/styles/tools";
@import "@app/styles/variables";

@mixin medium-breakpoint {
    @media screen and (max-width: 1400px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 900px) {
        @content;
    }
}

.block {
    max-width: 1544px;
    margin: 0 auto;
}

.block-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: max-content;
    gap: 28px;

    @include medium-breakpoint {
        grid-template-columns: repeat(2, 1fr);
    }

    @include media-tablet-portrait {
        grid-template-columns: repeat(1, 1fr);
    }
}
